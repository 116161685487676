import moment from "moment"
import { Boxed } from "./Boxed"
import Link from "next/link"

const ITEMS: {
  [key: string]: {
    label: string,
    href?: string,
    soon?: boolean
  }[]
} = {
  'WeSendit': [
    {
      label: 'Our Token',
      href: 'https://wesendit.io/our-token/'
    },
    {
      label: 'Solutions',
      href: 'https://wesendit.io/solutions/'
    },
    {
      label: 'Plans and Pricing',
      href: 'https://wesendit.com/plans-and-pricing/web3'
    },
    {
      label: 'About Us',
      href: 'https://discover.wesendit.com/about-us/'
    },
    {
      label: 'Partners',
      href: 'https://wesendit.io/partners/'
    },
    {
      label: 'Blog',
      href: 'https://wesendit.io/blog/'
    },
    {
      label: 'Buy WSI Token',
      href: '/'
    }
  ],
  'Documentations': [
    {
      label: 'Whitepaper',
      href: 'https://docs.wesendit.io'
    },
    {
      label: 'Utility Pitchdeck',
      href: 'https://docsend.com/view/4ffhjdd3vd9tbq72'
    },
    {
      label: 'Docs',
      href: 'https://docs.wesendit.io'
    }
  ],
  'Security': [
    {
      label: 'Hacken Audit',
      href: 'https://hacken.io/audits/wesendit/'
    },
    {
      label: 'Solidproof Audit',
      href: 'https://github.com/solidproof/projects/tree/main/WeSendit'
    }
  ],
  'Economics': [
    {
      label: 'Node NFT',
      href: '/nodes'
    },
    {
      label: 'Activity & Referral Reward Program',
      href: 'https://wesendit.io/rewards/'
    },
    {
      label: 'Circulating Supply',
      href: 'https://wesendit.io/circulating-supply/'
    },
    {
      label: 'WeSendit Burn Event',
      href: 'https://wesendit.io/wesendit-token-burn/'
    }
  ],
  'Support': [
    {
      label: 'Helpdesk',
      href: 'https://wesenditmediaag.zendesk.com/hc/en-us'
    },
    {
      label: 'FAQ',
      href: 'https://wesenditmediaag.zendesk.com/hc/en-us/categories/19510009233297-FAQ'
    }
  ],
  'Products / Features': [
    {
      label: 'Filetransfer',
      href: 'https://discover.wesendit.com/filetransfer/'
    },
    {
      label: 'File Management',
      href: 'https://discover.wesendit.com/filemanagement/'
    },
    {
      label: 'Transfer History',
      href: 'https://wesenditmediaag.zendesk.com/hc/en-us/articles/21011078417553-Transfer-history'
    },
    {
      label: 'Branding Service',
      href: 'https://discover.wesendit.com/all-features/'
    },
    {
      label: 'Address Book',
      href: 'https://wesenditmediaag.zendesk.com/hc/en-us/articles/21011303800849'
    },
    {
      label: 'Web2 Storage',
      soon: true
    },
    {
      label: 'Web3 Storage',
      soon: true
    },
    {
      label: 'Paid Download',
      soon: true
    },
    {
      label: 'Team Management',
      soon: true
    }
  ],
  'Contact Us': [
    {
      label: 'Talk to an Expert',
      href: 'https://wesendit.io/talk-to-an-expert/'
    },
    {
      label: 'Press Inquiries',
      href: 'mailto:jh@wesendit.com'
    },
    {
      label: 'Support Ticket',
      href: 'https://wesenditmediaag.zendesk.com/hc/en-us/requests/new'
    },
    {
      label: 'Enterprise Inquiries',
      href: 'mailto:jh@wesendit.com'
    },
    {
      label: 'Careers',
      href: 'mailto:jh@wesendit.com'
    }
  ],
  'Social': [
    {
      label: 'X (Twitter)',
      href: 'https://twitter.com/WeSendit'
    },
    {
      label: 'Instagram',
      href: 'https://www.instagram.com/wesendit.io/'
    },
    {
      label: 'LinkedIn',
      href: 'https://www.linkedin.com/company/wesendit'
    },
    {
      label: 'Medium',
      href: 'https://medium.com/@WeSendit'
    },
    {
      label: 'YouTube',
      href: 'https://www.youtube.com/channel/UCtzAZUlSqfoKiFuOWE521Ug'
    },
    {
      label: 'Meta',
      href: 'https://www.facebook.com/wesendit'
    },
    {
      label: 'Telegram (DE)',
      href: 'https://t.me/wesenditde'
    },
    {
      label: 'Telegram (EN)',
      href: 'https://t.me/wesenditcom'
    },
    {
      label: 'Discord (DE)',
      href: 'https://discord.gg/eqCQ82qn'
    },
    {
      label: 'Discord (EN)',
      href: 'https://discord.gg/FWZb4FTB'
    }
  ]
}

const Footer = () => {
  const year = moment().year()

  const buildList = (header: string) => {
    const items = ITEMS[header]

    return (
      <div className="flex flex-col gap-6">
        <span className="font-semibold">{header}</span>
        <ul className="list flex flex-col gap-2">
          {items.map(item => (
            <li key={item.label} className="flex items-center gap-4 hover:text-primary-tint transition-all">
              {item.soon &&
                <div>
                  <span className="uppercase bg-primary-tint p-0.5 text-[0.5rem] font-bold">Soon</span>
                </div>
              }
              {item.href ?
                <Link href={item.href} target={item.href.startsWith('/') ? '_self' : '_blank'}>
                  {item.label}
                </Link>
                :
                <span>{item.label}</span>
              }
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className='flex justify-center items-center w-full relative h-max bg-primary text-white text-sm lg:text-lg font-light'>
      <Boxed className="px-8">
        <Link className="pt-14 lg:pt-28 lg:place-self-start" href="/">
          <img
            className="w-[200px] lg:w-[300px]"
            alt="WeSendit Logo"
            src="/assets/img/logo-new-white.svg"
          />
        </Link>
        <div className="grid grid-cols-2 lg:grid-cols-6 gap-8 w-full py-14 lg:py-28 border-b border-white/50">
          {buildList('WeSendit')}
          <div className="flex flex-col gap-4">
            {buildList('Documentations')}
            {buildList('Security')}
          </div>
          <div className="flex flex-col gap-4">
            {buildList('Economics')}
            {buildList('Support')}
          </div>
          {buildList('Products / Features')}
          {buildList('Contact Us')}
          {buildList('Social')}
        </div>
        <div className='flex flex-col lg:flex-row text-center gap-6 justify-between w-full py-8'>
          <span className="max-lg:order-1">© {year} - WeSendit Media AG - all rights reserved.</span>
          <div className='flex gap-x-8 gap-y-4 lg:gap-16 justify-center items-center flex-wrap'>
            <a className="hover:text-primary-tint transition-all" href="https://wesendit.io/terms-of-service/" target='_blank' rel='noreferrer'>Terms of Service</a>
            <a className="hover:text-primary-tint transition-all" href="https://wesendit.io/imprint-legal-notice" target='_blank' rel='noreferrer'>Legal Notice</a>
            <a className="hover:text-primary-tint transition-all" href="https://wesendit.io/privacy-policy" target='_blank' rel='noreferrer'>Privacy Policy</a>
            <a className="hover:text-primary-tint transition-all" href="https://wesendit.io/imprint/" target='_blank' rel='noreferrer'>Imprint</a>
          </div>
        </div>
      </Boxed>
    </div>
  )
}

export default Footer