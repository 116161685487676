'use client'

import classNames from "classnames";
import { useTranslation } from "../app/i18n/client";
import Link from "next/link";
import { MdArrowDropDown, MdMenu } from "react-icons/md";
import { FaBurn, FaCircleNotch, FaClock, FaDatabase, FaHandHoldingUsd } from "react-icons/fa";
import { IconType } from "react-icons";
import { createElement } from "react";

interface Props {
  children: any;
  setSidebarOpen: Function;
}

interface MenuItem {
  label: string,
  icon?: string | IconType,
  description?: string,
  href?: string,
  target?: '_self' | '_blank',
  children?: MenuItem[]
}

export const getMenuItems = (): MenuItem[] => [
  {
    label: "our-token",
    href: "https://wesendit.io/our-token",
  },
  {
    label: "solutions",
    href: "https://wesendit.io/solutions/",
  },
  {
    label: "plans-and-pricing",
    href: "https://wesendit.com/plans-and-pricing/web3"
  },
  {
    label: "economics",
    children: [
      {
        label: 'Node NFT',
        icon: FaDatabase,
        description: 'Get your NFT Node Licence',
        href: '/nodes',
        target: '_self'
      },
      {
        label: 'Activity & Referral Reward Program',
        icon: FaHandHoldingUsd,
        description: 'Unlock rewards for referrals and using our platform, wesendit.com, with a WeSendit Web3 account & save up to 60% on your Basic, Pro, or Business subscription.',
        href: 'https://wesendit.io/rewards/',
        target: '_blank'
      },
      {
        label: 'Circulating Supply',
        icon: FaCircleNotch,
        description: 'The WeSendit circulating supply refers to the number of WSI tokens that are circulating in the market.',
        href: 'https://wesendit.io/circulating-supply/',
        target: '_blank'
      },
      {
        label: 'WeSendit Token Burn',
        icon: FaBurn,
        description: 'We conduct WeSendit burns as a means to decrease the overall supply of our native token.',
        href: 'https://wesendit.io/wesendit-token-burn/',
        target: '_blank'
      }
    ]
  },
  {
    label: "about-us",
    href: "https://discover.wesendit.com/about-us/",
  },
  {
    label: "partners",
    href: "https://wesendit.io/partners/",
  },
  {
    label: "blog",
    href: "https://wesendit.io/blog/"
  }
];

const Header = (props: Props) => {
  const { children, setSidebarOpen } = props;
  const { t } = useTranslation(undefined, "header");
  const items = getMenuItems()

  return (
    <header
      className={classNames("flex justify-between items-center px-8 py-[20px] bg-white max-w-[1600px] mx-auto", {})}
    >
      <Link href="/">
        <img
          className="w-[150px]"
          alt="WeSendit Logo"
          src="/assets/img/logo-new.svg"
        />
      </Link>
      <div className="hidden xl:flex gap-10 w-max items-center">
        <div className="flex w-max gap-8">
          {items.map((item) => (
            <div className="relative group/item" key={item.label}>
              <div className="flex gap-1 items-center h-10">
                {item.href ?
                  <Link href={item.href} target={item.target}>
                    <span className="text-lg font-semibold text-primary cursor-pointer hover:text-primary-shade transition-all">
                      {t(item.label)}
                    </span>
                  </Link>
                  :
                  <span className="text-lg font-semibold text-primary cursor-pointer hover:text-primary-shade transition-all">
                    {t(item.label)}
                  </span>
                }
                {item.children && <MdArrowDropDown className="text-2xl" />}
              </div>

              {item.children && (
                <div className="hidden absolute top-10 w-max group-focus-within/item:flex group-hover/item:flex flex-col gap-3 bg-white text-lg font-semibold p-4 rounded-2xl shadow-lg">
                  {item.children?.map(child => (
                    <Link href={child.href || ''} target={child.target} key={child.label}>
                      <div className="flex gap-6 items-center cursor-pointer w-96 p-4 hover:bg-light-blue transition-all rounded-2xl">
                        {child.icon &&
                          <div className="text-primary text-2xl">
                            {typeof child.icon === 'string' ?
                              <img src={child.icon} />
                              :
                              createElement(child.icon)
                            }
                          </div>
                        }
                        <div className="flex flex-col gap-2">
                          <span className="text-primary">{child.label}</span>
                          <span className="text-slate-400 text-xs">{child.description}</span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        {children}
      </div>
      <MdMenu
        className="block xl:hidden text-3xl"
        onClick={() => setSidebarOpen(true)}
      />
    </header>
  );
};

export default Header;
