'use client'

import { useEffect, useMemo, useState } from "react";
import { BigNumber, ethers } from "ethers";
import * as Sentry from "@sentry/browser";

import { useRefresh } from "./useRefresh";
import contracts from "../contracts/contracts.json";
import crowdsaleAbi from "../contracts/ICrowdsale.json";
import erc20Abi from "../contracts/IERC20.json";
import vestingAbi from "../contracts/VestingWallet.json";
import vaultAbi from '../contracts/TokenVault.json';
import pancakeRouterAbi from "../contracts/PancakeRouter.json";
import stakingPoolAbi from "../contracts/StakingPool.json";
import weStakeitTokenAbi from "../contracts/WeStakeitToken.json";
import multiVestingWalletAbi from "../contracts/MultiVestingWallet.json";
import stakingUtilsAbi from "../contracts/StakingUtils.json";
import IGatekeeperAbi from "../contracts/IGatekeeper.json";
import NodeManagerAbi from "../contracts/NodeManager.json";
import { ContractContext as VestingWalletContractContext } from "../contracts/types/VestingWallet";
import { ContractContext as VaultContractContext } from '../contracts/types/TokenVault';
import { ContractContext as ICrowdsaleContractContext } from "../contracts/types/ICrowdsale";
import { ContractContext as IERC20ContractContext } from "../contracts/types/IERC20";
import { ContractContext as PancakeRouterContext } from "../contracts/types/PancakeRouter";
import { ContractContext as StakingPoolContext } from "../contracts/types/StakingPool";
import { ContractContext as WeStakeitTokenContext } from "../contracts/types/WeStakeitToken";
import { ContractContext as MultiVestingWalletContext } from "../contracts/types/MultiVestingWallet";
import { ContractContext as StakingUtilsContext } from "../contracts/types/StakingUtils";
import { ContractContext as IGatekeeperContext } from "../contracts/types/IGatekeeper";
import { ContractContext as NodeManagerContext } from "../contracts/types/NodeManager";
import { useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { formatEther } from "ethers/lib/utils";

export enum Network {
  BSC_MAINNET = "BSC_MAINNET",
  BSC_TESTNET = "BSC_TESTNET",
}

const NETWORK_CONFIG: {
  [key in Network]: {
    rpcUrl: string;
    blockExplorerUrl: string;
    name: string;
    displayName: string;
    chainId: number;
  };
} = {
  [Network.BSC_MAINNET]: {
    rpcUrl: "https://bsc-dataseed1.binance.org/",
    blockExplorerUrl: "https://bscscan.com",
    name: "binance",
    displayName: "Binance Smart Chain Mainnet",
    chainId: 56,
  },
  [Network.BSC_TESTNET]: {
    rpcUrl: "https://data-seed-prebsc-1-s3.binance.org:8545/",
    blockExplorerUrl: "https://testnet.bscscan.com",
    name: "binance-testnet",
    displayName: "Binance Smart Chain Testnet",
    chainId: 97,
  },
};

export const ACTIVE_NETWORK = Network.BSC_MAINNET;
const CURRENT_NETWORK = NETWORK_CONFIG[ACTIVE_NETWORK];
export const CONTRACTS = contracts[ACTIVE_NETWORK];

const getWeb3NoAccount = () => {
  return new ethers.providers.StaticJsonRpcProvider(
    {
      url: CURRENT_NETWORK.rpcUrl,
      timeout: 30000,
    },
    {
      name: CURRENT_NETWORK.name,
      chainId: CURRENT_NETWORK.chainId,
    }
  );
};

const getContract = <T>(
  abi: any,
  address: string,
  web3?: ethers.providers.JsonRpcProvider
): T => {
  const _web3 = web3 ?? getWeb3NoAccount();
  return new ethers.Contract(address, abi, _web3) as unknown as T;
};

export const getCrowdsaleContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<ICrowdsaleContractContext>(crowdsaleAbi, address, web3);
};

export const getErc20TokenContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<IERC20ContractContext>(erc20Abi, address, web3);
};

export const getVestingWalletContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<VestingWalletContractContext>(vestingAbi, address, web3);
};

export const getVaultContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<VaultContractContext>(vaultAbi, address, web3);
};

export const getPancakeRouterContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<PancakeRouterContext>(pancakeRouterAbi, address, web3);
};

export const getStakingContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<StakingPoolContext>(stakingPoolAbi, address, web3);
};

export const getWeStakeitTokenContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<WeStakeitTokenContext>(weStakeitTokenAbi, address, web3);
};

export const getMultiVestingWalletContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<MultiVestingWalletContext>(multiVestingWalletAbi, address, web3);
};

export const getStakingUtilsContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<StakingUtilsContext>(stakingUtilsAbi, address, web3);
};

export const getGatekeeperContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<IGatekeeperContext>(IGatekeeperAbi, address, web3);
};

export const getNodeManagerContract = (
  address: string,
  web3?: ethers.providers.JsonRpcProvider
) => {
  return getContract<NodeManagerContext>(NodeManagerAbi, address, web3);
};

export const useWeb3 = ():
  | ethers.providers.Web3Provider
  | ethers.providers.JsonRpcProvider => {
  const provider = useWeb3ModalProvider()

  return provider.walletProvider
    ? new ethers.providers.Web3Provider(provider.walletProvider as any)
    : getWeb3NoAccount();
}

export const useAccount = () => {
  const web3 = useWeb3();
  const [account, setAccount] = useState<string>();
  const [signer, setSigner] = useState<ethers.Signer>();
  const { shouldRefresh, shouldManualRefresh, doManualRefresh } =
    useRefresh(5000);
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0));

  useEffect(() => {
    if (!web3 || !(web3 instanceof ethers.providers.Web3Provider)) {
      setAccount(undefined);
      setSigner(undefined);
      return;
    }

    const signer = web3.getSigner();
    setSigner(signer);
    signer
      .getAddress()
      .then((account) => setAccount(account))
      .catch(() => setAccount(undefined))
      .finally(() => {
        setTimeout(() => {
          doManualRefresh();
        }, 500);
      });
  }, [web3.connection.url, shouldManualRefresh, shouldRefresh]);

  useEffect(() => {
    if (!web3 || !(web3 instanceof ethers.providers.Web3Provider) || !account) {
      setBalance(BigNumber.from(0));
      return;
    }

    getBalance(account).then(setBalance);
  }, [web3.connection.url, shouldRefresh, account]);

  useEffect(() => {
    Sentry.setUser(account ? {
      id: account
    } : null);

    Sentry.setContext('web3_session', {
      account,
      balance: formatEther(balance),
      url: web3.connection.url
    })
  }, [account, balance, web3.connection.url])

  const getBalance = async (address: string): Promise<BigNumber> => {
    if (!address) {
      return BigNumber.from(0);
    }

    return await web3.getBalance(address);
  };

  return useMemo(
    () => ({
      account,
      signer,
      balance,
    }),
    [web3, shouldRefresh]
  );
};

// BUSD
export const useErc20TokenContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getErc20TokenContract(address, web3), [address, web3]);
};

// Crowdsale
export const useCrowdsaleContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getCrowdsaleContract(address, web3), [web3]);
};

export const useVestingWalletContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(
    () => getVestingWalletContract(address, web3),
    [address, web3]
  );
};

export const useVaultContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(
    () => getVaultContract(address, web3),
    [address, web3]
  );
};

export const usePancakeRouterContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(
    () => getPancakeRouterContract(address, web3),
    [address, web3]
  );
};

export const useStakingContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getStakingContract(address, web3), [web3]);
};

export const useWeStakeitTokenContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getWeStakeitTokenContract(address, web3), [web3]);
};

export const useMultiVestingWalletContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getMultiVestingWalletContract(address, web3), [web3]);
};

export const useStakingUtilsContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getStakingUtilsContract(address, web3), [web3]);
};

export const useGatekeeperContract = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getGatekeeperContract(address, web3), [web3])
}

export const useNodeManagerContract = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getNodeManagerContract(address, web3), [web3])
}
